import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import "firebase/firestore";
import { formatNumber, getAllTerms } from "../../firebaseConfig/firestore";
import InvestTermModal from "./Modal/InvestTermModal";
import EditTermUser from "./Modal/EditTermModal";

const AddNewTerm = ({ userId, onClose, refreshDetails }) => {
  const [fixedTerms, setFixedTerm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [termModalOpen, setTermModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedForEdit, setSelectedForEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);

  const fetchTerm = async () => {
    try {
      setIsLoading(true);
      const fetchedTerm = await getAllTerms();
      setFixedTerm(fetchedTerm);
    } catch (error) {
      console.error("Error fetching term:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTerm();
  }, []);

  const handleInvestSuccess = (investmentData, termId) => {
    setTermModalOpen(false);
    setIsEditing(true);
    setSelectedId(termId);
    setSelectedForEdit(investmentData);
  };

  const toggleDropdown = (index) => {
    if (visibleDropdownIndex === index) {
      setVisibleDropdownIndex(null); // if clicked again on the open dropdown, close it
    } else {
      setVisibleDropdownIndex(index); // open the clicked dropdown and close any other open dropdown
    }
  };

  return (
    <div className="fixedTermsContainer">
      <div className="contentSection">
        {isLoading && <LoadingScreen />}

        {!fixedTerms || fixedTerms.length === 0 ? (
          <h5 className="no_data">No Fixed Term Deposits Found.</h5>
        ) : (
          fixedTerms.map((fixedTerm, index) => (
            <div
              key={index}
              className="termCard"
              onClick={() => toggleDropdown(index)}
            >
              <div className="termDetails">
                <div className="termColumn">
                  <div className="bankLogoWrapper">
                    <img src={fixedTerm.logo} alt="" />
                  </div>
                  <div className="columnDetails">
                    <div className="bankDetails">
                      <p className="bankName"> {fixedTerm.bankName} </p>
                    </div>
                    <div className="additionalDetails">
                      <div className="termRow">
                        <p className="boldText">Term:</p>
                        <span className="regularText"> {fixedTerm.term} </span>
                      </div>
                      <div className="financialDetails">
                        <p className="boldText">Principal Amount:</p>
                        <span className="regularText">
                          $ {formatNumber(fixedTerm.minAmount)}
                        </span>
                      </div>
                    </div>
                    <div className="interestDetails">
                      <div className="interestWrapper">
                        <div className="interestRate">
                          <span className="interestNumber">
                            {" "}
                            {fixedTerm.interestRate}{" "}
                          </span>
                          <span className="percentageSymbol">%</span>
                        </div>
                        <span className="regularText">Interest Rate</span>
                      </div>
                    </div>
                    <input
                      type="submit"
                      value={`Purchase Fixed Term`}
                      onClick={() => {
                        setTermModalOpen(true);
                        setSelectedTerm(fixedTerm);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {termModalOpen && (
        <InvestTermModal
          onClose={() => {
            setTermModalOpen(false);
            setSelectedTerm(null);
          }}
          fixedTerm={selectedTerm}
          userId={userId}
          onInvestSuccess={handleInvestSuccess}
          refreshDetails={refreshDetails}
        />
      )}
      {isEditing && (
        <EditTermUser
          termId={selectedId}
          fixedTerm={selectedForEdit}
          onClose={onClose}
          userId={userId}
          refreshDetails={refreshDetails}
        />
      )}
      <div style={{ marginTop: "30px" }}>
        <input
          style={{ marginLeft: "12px" }}
          className="muted-button"
          type="button"
          value="Close"
          onClick={() => {
            fetchTerm();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default AddNewTerm;
