import React from 'react';

// import Logout from '../Logout';

const Header = () => {
  return (
    <header>
      <h1 className='header_title'>Document Management Dashboard</h1>
    </header>
  );
};

export default Header;
