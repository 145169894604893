import React, { useEffect, useState } from "react";
import {
  fetchChatFeature,
  fetchPasswordPolicySetting,
  fetchToolsFeature,
  fetchTermsFeature,
  fetchBondsFeature,
  fetchIposFeature,
  updateChatFeature,
  updatePasswordPolicySetting,
  updateToolsFeature,
  updateBondsFeature,
  updateIposFeature,
  updateTermsFeature,
  fetchIposTablesFeature,
  updateIposTablesFeature,
} from "../../firebaseConfig/firestore";
import "./style.css";

export default function GeneralSetting() {
  const [strongPasswordPolicy, setStrongPasswordPolicy] = useState(true);
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const [isToolsEnabled, setIsToolsEnabled] = useState(false);
  const [isBondsEnabled, setIsBondsEnabled] = useState(false);
  const [isIposEnabled, setIsIposEnabled] = useState(false);
  const [isTermsEnabled, setIsTermsEnabled] = useState(false);
  const [displayIposTables, setDisplayIposTables] = useState(false);

  const fetchPasswordSetting = async () => {
    try {
      const passwordPolicy = await fetchPasswordPolicySetting();
      setStrongPasswordPolicy(passwordPolicy);
    } catch (error) {
      console.error("Error fetching password policy:", error);
    }
  };

  const fetchChatSetting = async () => {
    try {
      const chatEnabled = await fetchChatFeature();
      setIsChatEnabled(chatEnabled);
    } catch (error) {
      console.error("Error fetching chat setting:", error);
    }
  };

  const fetchToolsSetting = async () => {
    try {
      const toolsEnabled = await fetchToolsFeature();
      setIsToolsEnabled(toolsEnabled);
    } catch (error) {
      console.error("Error fetching tools setting:", error);
    }
  };

  const fetchTermsSetting = async () => {
    try {
      const termsEnabled = await fetchTermsFeature();
      setIsTermsEnabled(termsEnabled);
    } catch (error) {
      console.error("Error fetching terms setting:", error);
    }
  };

  const fetchBondsSetting = async () => {
    try {
      const bondsEnabled = await fetchBondsFeature();
      setIsBondsEnabled(bondsEnabled);
    } catch (error) {
      console.error("Error fetching Bonds setting:", error);
    }
  };

  const fetchIposSetting = async () => {
    try {
      const iposEnabled = await fetchIposFeature();
      setIsIposEnabled(iposEnabled);
    } catch (error) {
      console.error("Error fetching Ipos setting:", error);
    }
  };

  const fetchIposTablesSetting = async () => {
    try {
      const tablesEnabled = await fetchIposTablesFeature();
      setDisplayIposTables(tablesEnabled);
    } catch (error) {
      console.error("Error fetching Tables setting:", error);
    }
  };

  useEffect(() => {
    fetchPasswordSetting();
    fetchChatSetting();
    fetchToolsSetting();
    fetchTermsSetting();
    fetchBondsSetting();
    fetchIposSetting();
    fetchIposTablesSetting();
  }, []);

  const togglePasswordPolicy = () => {
    const updatedValue = !strongPasswordPolicy;
    setStrongPasswordPolicy(updatedValue);

    updatePasswordPolicySetting(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating password policy: ", error);
      });
  };

  const toggleChatFeature = () => {
    const updatedValue = !isChatEnabled;
    setIsChatEnabled(updatedValue);

    updateChatFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating chat feature: ", error);
      });
  };

  const toggleToolsFeature = () => {
    const updatedValue = !isToolsEnabled;
    setIsToolsEnabled(updatedValue);

    updateToolsFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating tools feature: ", error);
      });
  };

  const toggleTermsFeature = () => {
    const updatedValue = !isTermsEnabled;
    setIsTermsEnabled(updatedValue);

    updateTermsFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating tools feature: ", error);
      });
  };

  const toggleBondsFeature = () => {
    const updatedValue = !isBondsEnabled;
    setIsBondsEnabled(updatedValue);

    updateBondsFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating tools feature: ", error);
      });
  };

  const toggleIposFeature = () => {
    const updatedValue = !isIposEnabled;
    setIsIposEnabled(updatedValue);

    updateIposFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating tools feature: ", error);
      });
  };

  const toggleTablesFeature = () => {
    const updatedValue = !displayIposTables;
    setDisplayIposTables(updatedValue);

    updateIposTablesFeature(updatedValue)
      .then(() => {})
      .catch((error) => {
        console.error("Error updating tools feature: ", error);
      });
  };

  return (
    <div className="passwordSetting_section">
      <div className="section_header">
        <h2 className="title">General Settings</h2>
        <p className="password_label">General Settings for the Client App. Check the box to enable the settings.</p>
      </div>
      <div className="form_group">
        <label>
          Enable Strong password policy:
        </label>
        <input
          type="checkbox"
          checked={strongPasswordPolicy}
          onChange={togglePasswordPolicy}
        />
      </div>
      {/* <div className="form_group">
        <label>Enable Chat menu visibilty for client app:</label>
        <input
          type="checkbox"
          checked={isChatEnabled}
          onChange={toggleChatFeature}
        />
      </div> */}
      <div className="form_group">
        <label>Enable Tools menu visibilty for client app:</label>
        <input
          type="checkbox"
          checked={isToolsEnabled}
          onChange={toggleToolsFeature}
        />
      </div>
      <div className="form_group">
        <label>Enable Bonds menu visibilty for client app:</label>
        <input
          type="checkbox"
          checked={isBondsEnabled}
          onChange={toggleBondsFeature}
        />
      </div>
      <div className="form_group">
        <label>Enable IPOs menu visibilty for client app:</label>
        <input
          type="checkbox"
          checked={isIposEnabled}
          onChange={toggleIposFeature}
        />
      </div>
      <div className="form_group">
        <label>Enable IPOs table and balance visibilty for client app:</label>
        <input
          type="checkbox"
          checked={displayIposTables}
          onChange={toggleTablesFeature}
        />
      </div>
      <div className="form_group">
        <label>Enable Terms menu visibilty for client app:</label>
        <input
          type="checkbox"
          checked={isTermsEnabled}
          onChange={toggleTermsFeature}
        />
      </div>
    </div>
  );
}
