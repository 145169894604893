import React from 'react';
import './style.css'; // Assuming the CSS above is saved in this file

const DotLoader = () => {
  return (
    <div className="loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default DotLoader;
