import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import {
  addStockToPortfolio,
  getCurrentDate,
} from "../../firebaseConfig/firestore";
import CurrencyInput from "react-currency-input-field";
import DotLoader from "../LoadingScreen/DotLoader";
import "./style.css";

const AddStock = ({ onClose, userId, stocks, setStocks, refreshDetails }) => {
  const [formData, setFormData] = useState({
    symbol: "",
    companyName: "",
    type: "Buy",
    shares: 0,
    tradeDate: getCurrentDate(),
    tradePrice: 0,
    marketPrice: 0,
    tradeAmount: 0,
    value: 0,
    profitLoss: 0,
    status: "Pending",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputValue, setInputValue] = useState("");

  const stockApiKey = process.env.REACT_APP_STOCK_API_KEY;
  // Refactored function to fetch and update stock data

  const fetchStockData = async () => {
    setIsLoading(true);
    setError("");
  
    const options = {
      method: 'GET',
      url: 'https://real-time-finance-data.p.rapidapi.com/stock-quote',
      params: { symbol: `${inputValue}`, language: 'en' },
      headers: {
        'X-RapidAPI-Key': stockApiKey, 
        'X-RapidAPI-Host': 'real-time-finance-data.p.rapidapi.com'
      }
    };
  
    try {
      const response = await axios.request(options);
      const data = response.data;

      if (data.status === "OK" && data.data.price) {
        const price = parseFloat(data.data.price).toFixed(2);
        updateStateWithStockData(price, data.data.name);
      } else {
        setError('No data available for the specified symbol.');
        setTimeout(() => resetForm(), 5000);
        
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
      setTimeout(() => setError(""), 3000);
      setError("Error fetching stock details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Simplified function to update the state based on latest stock data
  const updateStateWithStockData = (price, companyName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      marketPrice: price,
      companyName: companyName
    }));
  };

  useEffect(() => {
    recalculateDependentFields();
  }, [formData.type, formData.marketPrice, formData.shares, formData.tradePrice]);

  const recalculateDependentFields = () => {
    const { shares, tradePrice, marketPrice, type } = formData;
    const parsedShares = parseFloat(shares) || 0;
    const parsedTradePrice = parseFloat(tradePrice) || 0;
    const parsedMarketPrice = parseFloat(marketPrice) || 0;
  
    let tradeAmount, marketValue, profitLoss;
    tradeAmount = parsedShares * parsedTradePrice;
    marketValue = parsedShares * parsedMarketPrice;
  
    if (type === "Sell") {
      // When selling, calculate profit or loss
      profitLoss = (marketValue - tradeAmount) / tradeAmount * 100;
      console.log('sell',profitLoss, marketValue, tradeAmount);
    } else {
      // When buying, calculate total purchase price
      profitLoss = tradeAmount
      ? ((marketValue - tradeAmount) / tradeAmount) * 100
      : 0;
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      tradeAmount: tradeAmount.toFixed(2),
      value: marketValue.toFixed(2),
      profitLoss: profitLoss.toFixed(2),
    }));
  };
  
  // Centralized handling for input changes
  const handleInputChange = (e) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "tradePrice"; 
      value = e;
    }

    // Update the inputValue for symbol changes
    if (name === "symbol") {
      setInputValue(value);
      if (value) fetchStockData(value);
    } else {
      // For other fields, update formData directly
      const newFormData = { ...formData, [name]: value };
      setFormData(newFormData);
      // If shares or tradePrice change, recalculate dependent fields
      if (name === "shares" || name === "tradePrice") {
        recalculateDependentFields();
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleAddStock = async () => {
    // Update formData with the symbol from inputValue
    const updatedFormData = { ...formData, symbol: inputValue.toUpperCase() };

    // Check if required fields are filled
    const requiredFields = [
      "symbol",
      "companyName",
      "type",
      "shares",
      "tradePrice",
    ];
    const isFormValid = requiredFields.every(
      (field) => updatedFormData[field] !== "" && updatedFormData[field] !== 0
    );

    if (!isFormValid) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 3000);
      return;
    }

    setIsLoading(true);
    setError("");
    try {
      const result = await addStockToPortfolio(userId.userId, updatedFormData);

      if (result) {
        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `Stock added successfully.`,
          timer: 2000,
        });
        setStocks([...stocks, { ...updatedFormData, id: result.id }]);
        resetForm();
        refreshDetails();
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `Error adding stock: ${error.message}`,
      });
      console.error("Error adding stock:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Resets the form to initial state
  const resetForm = () => {
    setFormData({
      symbol: "",
      companyName: "",
      type: "Buy",
      shares: 0,
      tradeDate: getCurrentDate(),
      tradePrice: 0,
      marketPrice: 0,
      tradeAmount: 0,
      value: 0,
      profitLoss: 0,
      status: "Pending",
    });
    setInputValue("");
    setError("");
  };

  return (
    <div className="small-container" onClick={(e) => e.stopPropagation()}>
      <h2>Add Stock</h2>
      <br />
      <div className="add_stock_modal">
        <label htmlFor="symbol"> Search for stock symbol:</label>
        <div className="search_wrapper">
        <input
          type="text"
          id="symbol"
          name="symbol"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          required
        />
         {isLoading && (
         <DotLoader /> 
        )}
        {error === "No data available for the specified symbol." && (
          <p className="error_msg">{error}</p>
          )}
          <button onClick={fetchStockData} className="search_btn">Search</button>
      </div>

        <label htmlFor="companyName">Company Name:</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          required
          readOnly
        />

        <label htmlFor="type">Type:</label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleInputChange}
          required
        >
          <option value="Buy">Buy</option>
          <option value="Sell">Sell</option>
        </select>

        <label htmlFor="shares">Shares:</label>
        <input
          type="number"
          id="shares"
          name="shares"
          value={formData.shares}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="tradeDate">Trade Date:</label>
        <input
          type="date"
          id="tradeDate"
          name="tradeDate"
          value={formData.tradeDate}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="tradePrice">Trade Price:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="tradePrice"
          placeholder="$0.00"
          value={formData.tradePrice}
          decimalsLimit={2}
          onValueChange={(value) => handleInputChange(value)}
          required
        />

        <label htmlFor="marketPrice">Market Price:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="marketPrice"
          placeholder="$0.00"
          value={formData.marketPrice}
          decimalsLimit={2}
          onValueChange={(value) => handleInputChange(value, "marketPrice")}
          readOnly
          required
        />

        <label htmlFor="tradeAmount">Trade Amount:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="tradeAmount"
          placeholder="$0.00"
          value={formData.tradeAmount}
          decimalsLimit={2}
          onValueChange={(value) => handleInputChange(value, "tradeAmount")}
          required
          readOnly
        />

        <label htmlFor="value">Value:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="value"
          placeholder="$0.00"
          value={formData.value}
          decimalsLimit={2}
          onValueChange={(value) => handleInputChange(value, "value")}
          required
          readOnly
        />

        <label htmlFor="profitLoss">Profit/ Loss (%):</label>
        <input
          type="text"
          id="profitLoss"
          name="profitLoss"
          value={formData.profitLoss}
          onChange={handleInputChange}
          readOnly
        />

        <label htmlFor="status">Status:</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleInputChange}
          required
        >
          <option value="Pending">Pending</option>
          <option value="Outstanding">Outstanding</option>
          <option value="Paid">Paid</option>
          <option value="Sold">Sold</option>
        </select>

        <br />

        {error && <p className="error_msg">{error}</p>}

        <div className="buttons_wrap">
          <button onClick={handleAddStock} className="submit_btn">
            Add Stock
          </button>

          {isLoading && (
            <div className="spinner" style={{ margin: "0 auto" }}></div>
          )}

          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddStock;
