import React from 'react'

export default function Header() {
  return (
    <header>
      <h1 className="header_title">Live Chat</h1>
     
    </header>
  )
}
