import React from 'react';

const Header = () => {
  return (
    <header>
      <h1 className='header_title'>Banking Details Dashboard</h1>
    </header>
  );
};

export default Header;
