import React, { useState } from "react";
import { formatNumber } from "../../firebaseConfig/firestore";
import SellApprovalModal from "./Modals/SellApproveModal";
import InvestApprovalModal from "./Modals/InvestApprovalModal";

function Table({ ipoRequests, handleUpdateRequest, fetchIpoRequests }) {
  const [viewDetailId, setViewDetailId] = useState(null);
  const [selectedIpo, setSelectedIpo] = useState(null); // Add state for selected IPO
  const [selectedUserId, setSelectedUserId] = useState(null); // Add state for selected user ID
  const [modalOpen, setModalOpen] = useState(false);

  // Adjust handleModalOpen to accept IPO details and user ID
  const handleModalOpen = (ipo, userId) => {
    setSelectedIpo(ipo);
    setSelectedUserId(userId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedIpo(null);
    setSelectedUserId(null);
    setModalOpen(false);
  };

  const handleViewClick = (id) => {
    if (viewDetailId === id) setViewDetailId(null);
    else setViewDetailId(id);
  };

  const totalCost = (numberOfShares, sharePrice) => {
    return numberOfShares * sharePrice;
  };
  return (
    <div className="contain-table">
      {!ipoRequests.length === 0 ? (
        <h5 style={{ textAlign: "center" }}>NO REQUEST FOUND.</h5>
      ) : (
        <>
          <table className="striped-table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Shares No</th>
                <th>Type</th>
                <th>Total Cost</th>
                <th>Status</th>
                <th colSpan={3} className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <>
              {ipoRequests.map((request, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{request.userName}</td>
                    <td>{request.numberOfShares}</td>
                    <td>{request.type}</td>
                    <td>
                      ${" "}
                      {formatNumber(
                        totalCost(request.numberOfShares, request.sharePrice)
                      )}
                    </td>
                    <td>{request.status}</td>
                    <td className="text-right">
                      {request.type === "Invest" ? (
                        <button
                          onClick={() =>
                            handleUpdateRequest(
                              request.userId,
                              request.id,
                              "Approved"
                            )
                          }
                        >
                          Approve
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            handleModalOpen(request, request.userId);
                          }}
                        >
                          Approve
                        </button>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="button muted-button"
                        onClick={() =>
                          handleUpdateRequest(
                            request.userId,
                            request.id,
                            "Declined"
                          )
                        }
                      >
                        Decline
                      </button>
                    </td>
                    <td className="text-left">
                      {" "}
                      {viewDetailId === request.id ? (
                        <button onClick={() => handleViewClick(request.id)}>
                          Close
                        </button>
                      ) : (
                        <button onClick={() => handleViewClick(request.id)}>
                          View
                        </button>
                      )}
                    </td>
                  </tr>
                  {viewDetailId === request.id && (
                    <tr>
                      <td colSpan={5}>
                        <div className="dropdown_col">
                          <div className="dropdown_row">
                            <p className="bold_text">
                              Expected Listing Price :
                            </p>
                            <span className="reg_text">
                              $ {formatNumber(request.expListingPrice)}{" "}
                            </span>
                          </div>
                          <div className="dropdown_row">
                            <p className="bold_text">Expected Date :</p>
                            <span className="reg_text">
                              {" "}
                              {request.expectedDate}{" "}
                            </span>
                          </div>
                          <div className="dropdown_row">
                            <p className="bold_text">Share Price :</p>
                            <span className="reg_text">
                              $ {formatNumber(request.sharePrice)}{" "}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
            </>
          </table>
          {modalOpen &&
            selectedIpo &&
            selectedUserId &&
            (selectedIpo.type === "sell" ? (
              <SellApprovalModal
                ipo={selectedIpo}
                onClose={handleModalClose}
                fetchIpoRequests={fetchIpoRequests}
                userId={selectedUserId}
              />
            ) : selectedIpo.type === "invest" ? (
              <InvestApprovalModal
                ipo={selectedIpo}
                onClose={handleModalClose}
                fetchIpoRequests={fetchIpoRequests}
                userId={selectedUserId}
              />
            ) : null)}
        </>
      )}
    </div>
  );
}

export default Table;
