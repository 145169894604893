import { useEffect, useState } from "react";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { fetchPasswordPolicySetting } from "../../firebaseConfig/firestore";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdErrorOutline } from "react-icons/md";
import "./style.css";

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStrongPasswordPolicy, setIsStrongPasswordPolicy] = useState(true); 
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const validatePassword = (pass, isStrongPolicy) => {
    if (isStrongPolicy) {
      const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
      return regex.test(pass);
    } else {
      return pass.length >= 6;
    }
  };

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return false;
    }
    if (!validatePassword(newPassword, isStrongPasswordPolicy)) {
      setError(
        isStrongPasswordPolicy
          ? 'Password must be at least 8 characters long, must contain at least one number and a special character.'
          : 'Password must be at least 6 digits long.'
      );
      return false;
    }
    return true;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setTimeout(() => {
        setError("")
      }, 3000);
      return;
    }

    if (!validatePasswords()) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    reauthenticateWithCredential(user, credential)
      .then(() => {
        // User re-authenticated.
        return updatePassword(user, newPassword);
      })
      .then(() => {
        setSuccessMessage('Password updated successfully.');
        setTimeout(() => {
          setSuccessMessage("");
        }
        , 3000);
      })
      .catch((error) => {
        setError('Failed to update password. Please make sure your current password is correct.');
        console.error(error);
      })
        .finally(() => {
          setIsLoading(false);
        });
    
  };

  useEffect(() => {
    // Fetch the password policy setting from Firestore.
    fetchPasswordPolicySetting()
      .then((isStrong) => {
        setIsStrongPasswordPolicy(isStrong);
      })
      .catch((error) => {
        console.error('Error fetching password policy:', error);
      });
  }, []);

  return (
    <section className="changePassword_section">
      <div className="section_header">
        <h2 className="title">Change Password</h2>
        <p className="password_label">Password must be at least 8 characters long</p>
      </div>

      <form onSubmit={handleChangePassword} className="info_form">

        <div className="input_group">
          <label htmlFor="current_password" className="label_help">Current Password:
          </label>
          <div className="password_field">
            <input
              type={showPassword ? "text" : "password"}
              name="current_password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="pass_field"
            />
            {
              showPassword ? (
                <IoEye className="password_icon" onClick={togglePasswordVisibility} />
              ) : (
                <IoEyeOff className="password_icon" onClick={togglePasswordVisibility}/>
              )
            }
          </div>
        </div>

        <div className="input_group">
          <label htmlFor="new_password" className="label_help">New Password: 
          </label>
          <div className="password_field">
            <input
              type={showNewPassword ? "text" : "password"}
              name="new_password"
              className="pass_field"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {
              showNewPassword ? (
                <IoEye className="password_icon" onClick={toggleNewPasswordVisibility} />
              ) : (
                <IoEyeOff className="password_icon" onClick={toggleNewPasswordVisibility}/>
              )
            }
          </div>
        </div>

        <div className="input_group">
          <label htmlFor="confirm_password">Confirm Password:</label>
          <div className="password_field">
            <input
              className="pass_field"
              type={showPassword ? "text" : "password"}
              name="confirm_password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>

        {error && <p className="error_msg">
        <MdErrorOutline /> {" "}
          {error}
          </p>}
        {successMessage && <p className="success_msg">{successMessage}</p>}
        {isLoading ? (
          <button className="submit_btn" disabled>
            <div className="spinner"></div>
          </button>
        ) : (
          <button className="submit_btn">Update Account</button>
        )}
      </form>
    </section>
  );
}
