import React, { useState } from "react";
import {
  updateIposToUserCollection,
  deleteIposFromUserCollection,
  formatNumber,
} from "../../../firebaseConfig/firestore";
import Swal from "sweetalert2";
import "./style.css";

export default function EditIposUser({
  iposId,
  ipo,
  onClose,
  refreshDetails,
  userId,
}) {
  const [numberOfShares, setNumberOfShares] = useState(ipo.numberOfShares);
  const [purchaseDate, setPurchaseDate] = useState(ipo.date);
  const [type, setType] = useState(ipo.type);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    const investmentData = {
      logo: ipo.logo,
      name: ipo.name,
      expectedDate: ipo.expectedDate,
      sharePrice: ipo.sharePrice,
      expListingPrice: ipo.expListingPrice,
      date: purchaseDate,
      minInvestment: ipo.minInvestment,
      numberOfShares: numberOfShares,
      type: type,
    };
    setIsLoading(true);
    try {
      await updateIposToUserCollection(userId.userId, iposId, investmentData);
      Swal.fire({
        icon: "success",
        title: "Successful!",
        text: "You have successfully updated the investment on behalf of this user.",
        showConfirmButton: false,
        timer: 2000,
      });
      setNumberOfShares(0);
      onClose();
      refreshDetails();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an issue sending your investment request. Try again later.",
        showConfirmButton: false,
        timer: 2000,
      });
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteIposFromUserCollection(userId.userId, iposId);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully deleted this investment.",
        showConfirmButton: false,
        timer: 2000,
      });
      refreshDetails();
      onClose();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an issue deleting this investment. Try again later.",
        showConfirmButton: false,
        timer: 2000,
      });
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const totalCost = numberOfShares * ipo.sharePrice;

  const handleNumberOfSharesChange = (e) => {
    setNumberOfShares(e.target.value);
  };

  return (
    <div className="invest_ipo_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="invest_ipo_modal">
        <div className="section_header">
          <h6>Edit {ipo.name} IPOs for User</h6>
          <br />
          <img src={ipo.logo} alt={`${ipo.name} Logo`} className="logo" />
        </div>
        <div className="section_body">
          <div className="more_dets">
            <p className="bold_text">IPO Expected Date:</p>
            <p className="reg_text">{ipo.expectedDate}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">IPO Share Price: </p>
            <p className="reg_text">$ {formatNumber(ipo.sharePrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Expected Listing Price:</p>
            <p className="reg_text">$ {formatNumber(ipo.expListingPrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Minimum Investment Amount:</p>
            <p className="reg_text">$ {formatNumber(ipo.minInvestment)}</p>
          </div>
        <div className="more_dets">
          <p className="bold_text">Total Cost:</p>
          <p className="reg_text">$ {formatNumber(totalCost) || 0}</p>
        </div>
        </div>
        <div className="input_group">
          <label htmlFor="title">Number of Shares:</label>
          <input
            type="number"
            name="numberOfShares"
            id="numberOfShares"
            value={numberOfShares}
            onChange={handleNumberOfSharesChange}
            min="0"
          />
        </div>
        <div className="input_group">
          <label htmlFor="title">Type:</label>
          <select
            name="type"
            id="type"
            onChange={(e) => setType(e.target.value)}
            defaultValue={ipo.type}
          >
            <option value="invest">Invest</option>
            <option value="sell">Sell</option>
          </select>
        </div>

        <div className="input_group">
          <label htmlFor="purchaseDate">Purchase Date</label>
          <input
            type="date"
            name="purchase-date"
            value={purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
          />
        </div>
        {/* /</div> */}
        <div style={{ display: "flex" }}>
          <input type="submit" value="Save" onClick={handleUpdate} />
          <input
            style={{ marginLeft: "12px" }}
            className="reject_btn"
            type="button"
            value="Delete"
            onClick={handleDelete}
          />
          {isLoading && (
            <div className="spinner" style={{ marginLeft: "12px" }}></div>
          )}
          <input
            style={{ marginLeft: "auto", marginRight: "0" }}
            className="muted-button"
            type="button"
            value="Cancel"
            onClick={() => {
              onClose();
              setNumberOfShares(0);
            }}
          />
        </div>
      </div>
    </div>
  );
}
